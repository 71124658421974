import store from "@/store";

function getUrl() {
  // return store.state.user.systemSign == 1 ? "/hrm/outsource" : "/hro/customer";
  return store.state.user.systemSign == 1 ? "/outsource" : "/customer";
}

function getParams(query, page) {
  let params = {
    pageNum: page.current,
    pageSize: page.size,
  };
  for (let k of Object.keys(query)) {
    if (query[k]) {
      params[k] = query[k];
    }
  }
  return params;
}

// 供应商相关接口
export default function (axios) {
  return {
    // 获取供应商详情
    getBpoInfo(compId) {
      let url =
        store.state.user.systemSign == 1
          ? "/outsource/getOutsource"
          : "/customer/getCustomer";
      return axios.get(url, { params: { compId } });
    },
    // // 保存虚拟供应商
    // saveProvider(params) {
    //   return axios.post(`${getUrl()}/saveVirtualComp`, params);
    // },
    // 发送邀请
    sendInvite(compIds, requestComment) {
      return axios.post(`${getUrl()}/sendInvitation`, {
        compIds,
        reqFlag: store.state.user.systemSign,
        requestComment,
      });
    },
    // 新  激活邀请
    activatInvite(data) {
      return axios.post("/outsource/activatInvitation", data);
    },
    // 查询合作企业的邀请内容
    getRequestComment(params) {
      return axios.get("/outsource/getRequestComment", { params });
    },


    // 供应商/客户列表,
    // providerNoseList(params, sysType, nose) {
    //   let prefix = sysType == 1 ? "outsource" : "customer";
    //   let url = `/${prefix}/selectPage`;
    //   // nose参数控制接口读取的是否是需要token的，nose空读取需要token的接口；nose有值读取不需要token的接口
    //   url = url + (nose ? "Nose" : "");
    //   return axios.post(url, {}, { params });
    // },
    // 供应商列表,
    // providerList(query, page, nose) {
    //   let params = getParams(query, page);
    //   let url = `${getUrl()}/selectPage`;
    //   // nose参数控制接口读取的是否是需要token的，nose空读取需要token的接口；nose有值读取不需要token的接口
    //   url = url + (nose ? "Nose" : "");
    //   return axios.post(url, {}, { params });
    // },
    // // 供应商列表(简化版,nose传值“Nose”，注意大小写)
    // providerListApi(params, nose) {
    //   nose = nose ? nose : ""
    //   let url = `${getUrl()}/selectPage${nose}`;
    //   return axios.post(url, {}, { params });
    // },

    // 供应商搜索
    bpoSearch(query, page) {
      let params = getParams(query, page);
      return axios.post(`${getUrl()}/selectPageAll`, {}, { params });
    },
    // 我邀请的列表
    invitedList(query, page) {
      let params = getParams(query, page);
      params["reqStatus"] = params.status;
      return axios.post(`${getUrl()}/selectInvitedPage`, {}, { params });
    },
    // 我邀请的列表
    invitedListApi(params) {
      return axios.post(`${getUrl()}/selectInvitedPage`, {}, { params });
    },
    // 邀请我的列表
    approvedList(query, page) {
      let params = getParams(query, page);
      params["reqStatus"] = params.status;
      return axios.post(`${getUrl()}/selectApprovedPage`, {}, { params });
    },
    // 邀请我的列表
    approvedListApi(params) {
      return axios.post(`${getUrl()}/selectApprovedPage`, {}, { params });
    },
    // 终止合作
    stopCoop(params, sign) {
      let url = sign == 2 ? "/outsource/cancelCooperation" : "/customer/cancelCooperation";
      return axios.post(url, {}, { params });
    },
    // 恢复供应商
    recoverProvider(params, sign) {
      let url = sign == 2 ? "/outsource/resumeCooperation" : "/customer/resumeCooperation";
      return axios.post(url, {}, { params });
    },
    // 删除供应商
    removeProvider(compIds) {
      return axios.post(
        `${getUrl()}/deleteVirtualComp`,
        {},
        {
          params: { compIds },
        }
      );
    },
  };
}
