/*
 * @Description: 关联好招用户相关 Match Easy User Link Ctl
 * @Date: 2023-11-10 09:58:05
 * @LastEditTime: 2023-11-29 17:29:58
 */
export default function (axios) {
  return {
    // /matchEasyUserLinkCtl/getMatchEasyToken 获取好招token
    getMatchEasyToken() {
      return axios.get("/matchEasyUserLinkCtl/getMatchEasyToken");
    },

    // /matchEasyUserLinkCtl/insertCompany 新增好招企业
    insertMatchEasyCompany(data) {
      return axios.post("/matchEasyUserLinkCtl/insertCompany", data);
    },

    // /matchEasyUserLinkCtl/insertCompStaffInfo  新增好招员工
    insertMatchEasyCompStaffInfo(data) {
      return axios.post("/matchEasyUserLinkCtl/insertCompStaffInfo", data, {
        headers: {
          // noErrorAlert: true,
        },
      });
    },
  };
}
