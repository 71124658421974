// 订单
export default function (axios) {
  return {
    // 新增订单
    addOrder(params) {
      return axios.post("/orderCtl/addOrder", params);
    },
    // 新增订单阶段
    addOrderStage(params) {
      return axios.post("/orderCtl/addOrderStage", params);
    },
    // 审批订单
    approveOrder(params) {
      return axios.post("/orderCtl/approveOrder", params);
    },
    // 新增订单之前检查是否有流程
    checkStage(params) {
      return axios.post(
        "/orderCtl/checkStage?type=" + params.type + "&flag=" + params.flag
      );
    },
    // 删除订单阶段(> 0删除成功)
    delOrderStage(params) {
      return axios.post(`/orderCtl/delOrderStage/${params.processId}`);
    },
    // 流程回显
    getProcessList(params, nose) {
      nose = nose ? nose : ""
      return axios.post(
        "/orderCtl/getProcessList" + nose + "?type=" + params.type + "&flag=" + params.flag
      );
    },
    // 废除订单，多个订单id逗号分割
    repealOrder(params) {
      return axios.post("/orderCtl/repealOrder?orderIds=" + params.orderIds);
    },
    // 订单详情（编辑回显 与 查看详情）
    selectApproveInfo(params) {
      const queryParams = Object.keys(params)
        .map((key) => `${key}=${params[key]}`)
        .join("&");

      return axios.post("/orderCtl/selectApproveInfo?" + queryParams);
    },
    // 获取某个流程阶段的审批人
    selectApproveStageInfo(params, nose) {
      nose = nose ? nose : ""
      return axios.post(
        `/orderCtl/selectApproveStageInfo${nose}/${params.stageId}/${params.processId}`
      );
    },
    // 订单列表分页
    selectOrderInfoPage(params) {
      return axios.post("/orderCtl/selectOrderInfoPage", params);
    },
    // 获取可以驳回的阶段
    selectReApproveInfoList(params) {
      return axios.post(
        `/orderCtl/selectReApproveInfoList/${params.stageId}/${params.processId}/${params.orderId}`
      );
    },
    // 修改订单
    updateOrderReq(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderCtl/updateOrder" + nose, params);
    },
  };
}
