export default function (axios) {
  return {


    // 根据登录人员查询这个公司下的客户公司
    selectCustomerListApi(sign, params, nose) {
      nose = nose ? nose : ""
      let url = (sign == 2 ? "/outsource/selectOutsourceList" : "/customer/selectCustomerList") + nose;
      return axios.get(url, { params });
    },

    // 公司供应商列表分页查询
    outsourceSelectPage(params) {
      return axios.post("/outsource/selectPage", params);
    },
    // 公司客户列表分页查询
    customerSelectPage(params) {
      return axios.post("/customer/selectPage", params);
    },
    // ***********************************************************************************客户
    // 客户——列表分页查询
    customerSelectListPage(params, sign) {
      let url = sign == 2 ? "/outsource/selectPage" : "/customer/selectPage";
      return axios.post(url, params);
    },
    // 客户——列表筛选条件——地点
    selectCitysApi(params, sign) {
      let url = sign == 2 ? "/outsource/selectCitys" : "/customer/selectCitys";
      return axios.get(url, { params });
    },

    // 客户——保存（新增、编辑）
    saveCustomerApi(params, sign) {
      let url =
        sign == 2 ? "/outsource/saveVirtualComp" : "/customer/saveCustomer";
      return axios.post(url, params);
    },
    // 客户——校验企业是否存在
    selectIsActiveApi(params) {
      return axios.get("/outsource/selectIsActive", { params });
    },

    // 客户——查询详情
    getCustomerApi(params, sign) {
      let url = sign == 2 ? "/outsource/getOutsource" : "/customer/getCustomer";
      return axios.get(url, { params });
    },
    // 客户——删除
    deleteVirtualCompApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/deleteVirtualComp"
          : "/customer/deleteVirtualComp";
      return axios.post(url, {}, { params });
    },
    // 客户——详情页中相关栏目的数据统计
    selectOutSourceSummaryApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/selectOutSourceSummary"
          : "/customer/selectCustomerSummary";
      return axios.get(url, { params });
    },
    // 客户——修改跟进阶段
    editFollowUpPeriodApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/editFollowUpPeriod"
          : "/customer/editFollowUpPeriod";
      return axios.get(url, { params });
    },
    // 客户——查询列表跟进人选项
    selectFollUpPersonsApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/selectFollUpPersons"
          : "/customer/selectFollUpPersons";
      return axios.get(url, { params });
    },

    // 保存并邀请——对已存在真实公司的客户发起新增时
    sendInviteApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/sendInvitation"
          : "/customer/sendInvitation";
      return axios.post(url, params);
    },
    // 激活邀请
    activatInvitationApi(data) {
      return axios.post("/outsource/activatInvitation", data);
    },

    // 处理请求
    makeApproveApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/approve"
          : "/customer/approve";
      return axios.post(url, params);
    },

    // 客户-邀请
    selectInviteAllListApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/selectInviteAllList"
          : "/customer/selectInviteAllList";
      return axios.post(url, {}, { params });
    },

    // 客户-邀请-完善信息：根据联系人id查询联系人信息（免token）
    selectContactByIdNoseApi(params) {
      let url = "/outsource/selectContactByIdNose";
      return axios.get(url, { params });
    },



    // ***********************************************************************************跟进记录
    // 跟进记录——列表查询
    selectRecordListApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/selectRecordList"
          : "/customer/selectRecordList";
      return axios.post(url, params);
    },
    // 跟进记录——保存（新增、编辑）
    editFollowUpRecordApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/editFollowUpRecord"
          : "/customer/editFollowUpRecord";
      return axios.post(url, params);
    },
    // 跟进记录——删除
    deleteFollowUpRecordApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/deleteFollowUpRecord"
          : "/customer/deleteFollowUpRecord";
      return axios.get(url, { params });
    },
    // 跟进记录——新增评论
    editCommentApi(params, sign) {
      let url = sign == 2 ? "/outsource/editComment" : "/customer/editComment";
      return axios.post(url, params);
    },
    // 跟进记录——删除评论
    deleteCommentApi(params, sign) {
      let url =
        sign == 2 ? "/outsource/deleteComment" : "/customer/deleteComment";
      return axios.get(url, { params });
    },

    //
    // ***********************************************************************************跟进计划
    // 跟进计划——列表查询
    selectPlanListApi(params, sign) {
      let url =
        sign == 2 ? "/outsource/selectPlanList" : "/customer/selectPlanList";
      return axios.post(url, params);
    },
    // 跟进计划——保存（新增、编辑）
    editFollowUpPlanApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/editFollowUpPlan"
          : "/customer/editFollowUpPlan";
      return axios.post(url, params);
    },
    // 跟进计划——删除
    deleteFollowUpPlanApi(params, sign) {
      let url =
        sign == 2
          ? "/outsource/deleteFollowUpPlan"
          : "/customer/deleteFollowUpPlan";
      return axios.get(url, { params });
    },

    // 虚拟企业详情免token
    getVirtualCompInfo(params) {
      return axios.get("/outsource/getVirtualCompInfoNose", { params });
    },

    // 保存发票客户设置
    editInvoiceApi(params, sign) {
      let url =
        sign == 2 ? "/outsource/editOutsourceInvoice" : "/customer/editCustomerInvoice";
      return axios.post(url, params);
    },

    // editCustomerInvoice

    // ***********************************************************************************消息
    // 消息——未读消息数量
    // getOperationByUserReq(params){
    //   let url ="/news/selectNews";
    //   return axios.get(url, { params });
    // },
    // 消息——读取列表
    selectNewsApi(params) {
      let url = "/news/selectNews";
      return axios.post(url, params);
    },
    // 消息——设置已读
    readNewsApi(params) {
      let url = "/news/readNews";
      return axios.post(url, {}, { params });
    },


    // 好招猎头同步保存客户
    saveHeadCustomerApi(params) {
      let url = "/customer/saveHeadCustomer";
      return axios.post(url, params);
    },
    // 获取一个管理员（查询公司的管理员接口）
    selectAdminApi(params) {
      let url = "/customer/selectAdmin";
      return axios.get(url, params);
    },



  };
}
