/*
 * @Description: 阿仕图-心理测评 E User Exam Ctl
 * @Date: 2024-01-12 17:15:29
 * @LastEditTime: 2024-01-15 16:26:12
 */
export default function (axios) {
  return {
    // /asktrue/exam/addStaffStudentRel 关联员工与阿仕图测评考生(可批量)
    addStaffStudentRel(data) {
      return axios.post(`/asktrue/exam/addStaffStudentRel`, data);
    },

    // /asktrue/exam/selectExamIds 查询“我参与”的测评项目
    selectExamIds() {
      return axios.post(`/asktrue/exam/selectExamIds`);
    },

    // /asktrue/exam/saveExamScope  保存项目使用范围
    saveExamScope(data) {
      return axios.post(`/asktrue/exam/saveExamScope`, data);
    },

    // /asktrue/exam/selectExamScope  查询项目使用范围
    selectExamScope(params) {
      return axios.get(`/asktrue/exam/selectExamScope`, {
        params,
      });
    },

    // /asktrue/exam/selectExamIdsAll  查询“全部”的测评项目(员工账号请求，在项目使用范围内或者项目创建者所在部门的负责人)
    selectExamIdsAll(data) {
      return axios.post(`/asktrue/exam/selectExamIdsAll`, data);
    },

    // 活动报名新增（关联员工与阿仕图活动报名考生）-可批量
    addRegistrationRel(data) {
      return axios.post(`/asktrue/registration/addRegistration`, data);
    },
    // /asktrue/registration/saveEnrollScope保存项目使用范围
    saveEnrollScope(data) {
      return axios.post(`/asktrue/registration/saveEnrollScope`, data);
    },
    // /asktrue/registration/selectPage 活动报名（我参与）的查询
    selectActivityPage(data) {
      return axios.get(`/asktrue/registration/selectPage`, { data });
    },
    // /asktrue/registration/selectEnrollScope 查询项目使用范围
    selectEnrollScope(data) {
      return axios.get(`/asktrue/registration/selectEnrollScope`, { data });
    },
    // /asktrue/registration/selectExamIdsAll 查询“全部”的活动报名项目(员工账号请求-在项目使用范围内或者项目创建者所在部门的负责人)
    selectActivityIdsAll(data) {
      return axios.post(`/asktrue/registration/selectExamIdsAll`, data);
    },
    // 新增云掌门人员与阿仕图问卷调查记录
    addUserInvestigationReq(data) {
      return axios.post(`/investigation/addUserInvestigation`, data);
    },

    // 删除云掌门人员与阿仕图问卷调查记录
    deleteUserInvestigationReq(params) {
      return axios.get(`/investigation/deleteUserInvestigation`, { params });
    },

    // 保存项目使用范围
    saveExamScopeReq(data) {
      return axios.post(`/investigation/saveExamScope`, data);
    },
    // 查询我参与的和我创建的问卷调查项目
    selectExamIdsReq(params) {
      return axios.get(`/investigation/selectExamIds`, { params });
    },

    // 查询“全部”的测评项目(员工账号请求，在项目使用范围内或者项目创建者所在部门的负责人)
    selectExamIdsAllReq(data) {
      return axios.post(`/investigation/selectExamIdsAll`, data);
    },

    // 查询项目使用范围
    selectExamScopeReq(params) {
      return axios.get(`/investigation/selectExamScope`, {
        params,
      });
    },

    // 关联员工与阿仕图测评考生(可批量)
    addStaffStudentRelReq(data, type) {
      let url = "";
      if (type == 1) {
        url = "/asktrue/exam/addStaffStudentRel";
      } else if (type == 4) {
        url = "/asktrue/registration/addRegistration";
      } else if (type == 2) {
        url = "/investigation/addUserInvestigation";
      }
      return axios.post(url, data);
    },
  };
}
