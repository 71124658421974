/*
 * @Description: 电子签约
 * @Date: 2024-01-16 15:05:06
 * @LastEditTime: 2024-02-23 09:26:14
 */
export default function (axios) {
  return {
    // 获取腾讯电子签web嵌入页面url
    emberPageUrl(params) {
      return axios.get(`/sign/emberPageUrl`, {
        params,
      });
    },

    // /sign/loginPageUrl  获取腾讯电子签-子客注册链
    signGetLoginPageUrl(params) {
      return axios.get(`/sign/loginPageUrl`, {
        params,
      });
    },

    // /sign/prepareFlowUrl  创建发起流程web页面url
    signPrepareFlowUrl(params) {
      return axios.get(`/sign/prepareFlowUrl`, {
        params,
      });
    },
    // /sign/selectTemplates 获取腾讯电子签-模板列表
    selectTemplates(params) {
      return axios.get(`/sign/selectTemplates`, {
        params,
      });
    },

    // /sign/uploadFile 上传文件、模板
    signUploadFile(params) {
      return axios.post(
        `/sign/uploadFile`,
        {},
        {
          params,
        }
      );
    },

    // /sign/getOrganizationSignUrl/获取企业签署合同web页面
    getOrganizationSignUrl(params) {
      return axios.get(`/sign/getOrganizationSignUrl`, {
        params,
      });
    },

    // /sign/getSignUserList  查询员工列表
    getSignUserList(params) {
      return axios.get("/sign/getSignUserList", {
        params,
      });
    },

    // /sign/sendSignSms 短信发送通知腾讯电子签认证
    sendSignSms(params) {
      return axios.get("/sign/sendSignSms", {
        params,
      });
    },

    // /sign/selectContractDetail   获取合同信息
    signSelectContractDetail(params) {
      return axios.post(
        `/sign/selectContractDetail`,
        {},
        {
          params,
        }
      );
    },
  };
}
