var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"main-warpper console-body asktrue"},[_c('header',[_c('div',{staticClass:"left"},[_c('router-link',{staticClass:"back cur",attrs:{"to":{ name: '360' }}},[_c('em',{staticClass:"el-icon-arrow-left"})]),_c('h1',{staticClass:"ellipsis"},[_c('span',{attrs:{"title":_vm.proData.exam_name}},[_vm._v(_vm._s(_vm.proData.exam_name))]),(_vm.proData.exam_status == 1)?_c('em',{staticClass:"iconfont iconshangxian",attrs:{"title":"点击下线"},on:{"click":function($event){return _vm.examStatusChange()}}}):_vm._e(),(_vm.proData.exam_status == 3)?_c('em',{staticClass:"iconfont iconxiaxian",attrs:{"title":"点击上线"},on:{"click":function($event){return _vm.examStatusChange()}}}):_vm._e()])],1),_c('div',{staticClass:"menuUl no-select"},[(_vm.ftype == 2)?_c('router-link',{class:{ on: _vm.activedRouteName == 'ProPgQuestionnaire' },attrs:{"to":{
          name: 'ProPgQuestionnaire',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype },
        }}},[_c('span',[_vm._v("问卷")])]):_vm._e(),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProPgEvaluate' },attrs:{"to":{
          name: 'ProPgEvaluate',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype },
        }}},[_c('span',[_vm._v("评价")])]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProPgData' },attrs:{"to":{
          name: 'ProPgData',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype },
        }}},[_c('span',[_vm._v("数据")])]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProPgScore' },attrs:{"to":{
          name: 'ProPgScore',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype },
        }}},[_c('span',[_vm._v("成绩")])]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProPgStatistics' },attrs:{"to":{
          name: 'ProPgStatistics',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype },
        }}},[_c('span',[_vm._v("统计")])]),_c('router-link',{class:{ on: _vm.activedRouteName == 'ProPgNotice' },attrs:{"to":{
          name: 'ProPgNotice',
          query: { id: _vm.id, type: _vm.type, ftype: _vm.ftype },
        }}},[_c('span',[_vm._v("通知")])])],1),_c('div',{staticClass:"btn-area"},[_c('span',{attrs:{"title":"基本设置"},on:{"click":function($event){return _vm.showPopup('isEdit')}}},[_c('em',{staticClass:"iconfont iconshezhi"}),_c('span',[_vm._v("编辑")])]),_c('span',{attrs:{"title":"链接设置"},on:{"click":function($event){return _vm.showPopup('isShare')}}},[_c('em',{staticClass:"iconfont iconfenxiang"}),_c('span',[_vm._v("链接")])]),_c('span',{attrs:{"title":"外观设置"},on:{"click":function($event){return _vm.showPopup('isStyle')}}},[_c('em',{staticClass:"iconfont iconwaiguan"}),_c('span',[_vm._v("外观")])]),_c('a',{staticClass:"btn",attrs:{"href":_vm.proData.specify_student == 2 && !_vm.proData.exam_text
            ? `https://asktrue.co/#/exam/main/${_vm.proData.exam_id}/${_vm.proData.projectType}/${_vm.proData.vip_id}`
            : (_vm.proData.projectType == 2 || _vm.proData.projectType == 3) &&
              _vm.proData.real_name == 2
            ? `https://asktrue.co/#/exam/loginpg/${_vm.proData.exam_id}/${_vm.proData.projectType}/${_vm.proData.vip_id}/${_vm.proData.create_id}`
            : `https://asktrue.co/#/exam/login/${_vm.proData.exam_id}/${_vm.proData.projectType}/${_vm.proData.vip_id}/${_vm.proData.create_id}`,"target":"_blank"}},[_vm._m(0)])]),_c('router-link',{staticClass:"close el-icon-close",attrs:{"title":"关闭","to":{
        name: '360',
      }}})],1),_c('router-view'),_c('el-drawer',{key:_vm.popup.key,attrs:{"visible":_vm.popup.show,"size":_vm.popup.size,"before-close":_vm.handleClose,"show-close":_vm.popup.isStyle ? false : true,"custom-class":"asktrue-drawer"},on:{"update:visible":function($event){return _vm.$set(_vm.popup, "show", $event)}}},[(_vm.popup.isEdit)?_c('EditForm',{attrs:{"isOnOff":_vm.isOnOff,"proData":_vm.proData},on:{"refresh":function($event){_vm.handleClose('isEdit');
        _vm.getProjectEditFn();}}}):_vm._e(),(_vm.popup.isShare)?_c('SharePopup',{attrs:{"source":"xm","proData":_vm.proData,"examData":{
        examId: _vm.id,
        isJk: _vm.proData.face_recognition,
        type: _vm.type,
        vipId: _vm.proData.vip_id,
        goodStatus: _vm.proData.goods_status,
      }},on:{"closePop":function($event){return _vm.handleClose('isShare')}}}):_vm._e(),(_vm.popup.isStyle)?_c('StylePopup',{attrs:{"id":_vm.proData.exam_id,"type":_vm.proData.projectType,"ftype":_vm.proData.from_type},on:{"close":function($event){return _vm.handleClose('isStyle')}}}):_vm._e()],1)],1)
}
var staticRenderFns = [function (){var _vm=this,_c=_vm._self._c;return _c('span',[_c('em',{staticClass:"iconfont iconpreview1",attrs:{"title":"预览"}}),_c('span',[_vm._v("预览")])])
}]

export { render, staticRenderFns }