export default function (axios) {
  return {
    // 发票-增、改
    InvEditApi(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderInvoiceCtl/editInvoice" + nose, params);
    },
    // 发票-删
    InvDelApi(params) {
      return axios.post("/orderInvoiceCtl/repealInvoice", {}, { params });
    },
    //发票-查（列表）
    InvListApi(params) {
      return axios.post("/orderInvoiceCtl/selectInvoicePage", params);
    },
    // 发票-查（详情）
    InvDetailApi(params, nose) {
      nose = nose ? nose : ""
      return axios.get("/orderInvoiceCtl/selectOrderInvoiceId" + nose, { params });
    },
    //  更改发票是否已回款状态
    InvFlagApi(params) {
      return axios.post("/orderInvoiceCtl/editInvoiceFlag", params);
    },
    //  更改发票是否已回款状态
    selectInvoiceRecordApi(params) {
      return axios.post("/orderInvoiceCtl/selectInvoiceRecord", params);
    },


  };
}
