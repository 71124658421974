// 薪酬工资-交易订单
export default function (axios) {
  return {
    // 查--列表：订单列表分页
    jydd_selectSalaryOrderPage(params) {
      return axios.post("orderSalaryCtl/selectSalaryOrderPage", params);
    },
    // 订单详情（状态、发票等）
    jydd_selectOrderSalaryInfo(params, nose) {
      nose = nose ? nose : ""
      let url = "/orderSalaryCtl/selectOrderSalaryInfo" + nose
      return axios.post(url, {}, { params });
    },

    // 查询订单操作记录
    jydd_selectOperateRecord(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/selectOperateRecord" + nose, {}, { params });
    },

    // 订单提交客户确认
    jydd_submitOrder(params) {
      return axios.post("/orderSalaryCtl/submitOrder", {}, { params });
    },

    // 客户发起订单审批
    jydd_approveOrder(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/approveOrder" + nose, {}, { params });
    },
    // 客户确认订单并申请发票
    jydd_confirmOrder(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/confirmOrder" + nose, {}, { params });
    },

    // 开发票
    jydd_submitInvoice(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/submitInvoice" + nose, params);
    },

    //客户确认发票
    jydd_confirmInvoice(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/confirmInvoice" + nose, {}, { params });
    },
    // 提交付款凭证
    jydd_submitProof(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/submitProof" + nose, params);
    },


    // 确认回款
    jydd_confirmPay(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/confirmPay" + nose, params);
    },

    // 发票凭证、付款凭证(发起审批时附件/审批意见中附件：存在勾选为凭证的附件时执行此方法)
    jydd_addInvoiceAttach(list, params, type, nose) {
      // type=1发票凭证；2付款凭证
      nose = nose ? nose : ""
      let url = (type == 1 ? "/orderInvoiceCtl/addInvoiceAttach" : "/payBack/addBackAttach") + nose
      return axios.post(url, list, { params });
    },

    // 交易订单中生成下载文件
    jydd_down(params) {
      let url = "/ossApi/zipFilesNose";
      return axios.post(url, params);
    },



    // 退回订单
    jydd_rejectOrder(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/rejectOrder" + nose, {}, { params });
    },
    // 退回发票
    jydd_rejectInvoice(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/rejectInvoice" + nose, {}, { params });
    },
    // 退回付款
    jydd_rejectPay(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/orderSalaryCtl/rejectPay" + nose, params);
    },

    // 发票（type=1）、付款（type=2）审批驳回（内部不同意）
    jydd_disagree(params, type) {
      let url = type == 1 ? "/orderInvoiceCtl/rejectInvoiceAttachNose" : "/payBack/rejectBackAttachNose"
      return axios.post(url, {}, { params });
    },




  };
}
