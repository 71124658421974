/*
 * @Description: crm product
 * @Date: 2024-01-22 15:39:27
 * @LastEditTime: 2024-01-29 17:39:34
 */
export default function (axios) {
  return {
    // /product/selectPage 产品列表
    getCrmProductList(data) {
      return axios.post("/product/selectPage", data);
    },

    // /product/saveProduct 保存产品
    saveCrmProduct(data) {
      return axios.post("/product/saveProduct", data);
    },

    // /product/getProductById 产品详情
    getCrmProductDetail(params) {
      return axios.get("/product/getProductById", { params });
    },

    // /product/deleteByIds 删除产品（可批量）
    deleteCrmProductsByIds(params) {
      return axios.post("/product/deleteByIds", {}, { params });
    },

    // /product/getOutStaffCount  客户详情统计业务外包、劳务派遣人数
    getCrmOutStaffCount(params) {
      return axios.get("/product/getOutStaffCount", { params });
    },

    // /product/selectUnitPage  产品单位列表
    selectProductUnitPage() {
      return axios.post("/product/selectUnitPage");
    },

    // /product/deleteUnitByIds 删除产品单位（可批量）
    deleteCrmProductUnitByIds(params) {
      return axios.post("/product/deleteUnitByIds", {}, { params });
    },

    // /product/saveProductUnit  保存产品单位
    saveCrmProductUnit(data) {
      return axios.post("/product/saveProductUnit", data);
    },

    // /product/selectTypePage  产品类型列表
    selectProductTypePage() {
      return axios.post("/product/selectTypePage");
    },

    // /product/deleteTypeByIds 删除产品类型（可批量）
    deleteCrmProductTypeByIds(params) {
      return axios.post("/product/deleteTypeByIds", {}, { params });
    },

    // /product/saveProductType  保存产品类型
    saveCrmProductType(data) {
      return axios.post("/product/saveProductType", data);
    },

    // /product/saveProductSet  保存产品设置
    saveCrmProductSet(data) {
      return axios.post("/product/saveProductSet", data);
    },
    // /product/getProductSet 查询产品设置
    getCrmProductSet() {
      return axios.get("/product/getProductSet");
    },

    // /product/getProductCount  客户详情统计其他产品数据
    getCrmProductCount(params) {
      return axios.get("/product/getProductCount", { params });
    },
  };
}
