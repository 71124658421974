<template>
  <div class="main-warpper console-body asktrue">
    <header>
      <div class="left">
        <router-link
          :to="{
            name: 'Activity',
            params: { tab: 'Activity', back: '1' },
          }"
          class="back cur no-select"
          ><em class="el-icon-back"></em
        ></router-link>
        <h1 class="ellipsis">
          <span :title="proData.exam_name">{{ proData.exam_name }}</span>
          <em
            class="iconfont iconshangxian"
            @click="examStatusChange()"
            title="点击下线"
            v-if="proData.exam_status == 1"
          ></em>
          <em
            class="iconfont iconxiaxian"
            @click="examStatusChange()"
            title="点击上线"
            v-if="proData.exam_status == 3"
          ></em>
        </h1>
      </div>
      <div class="menuUl no-select">
        <router-link
          :to="{
            name: 'ProBmQuestionnaire',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProBmQuestionnaire' }"
          v-if="ftype == 2"
          >问卷</router-link
        >
        <!--调查-认证-实名↓-->
        <router-link
          :to="{
            name: 'ProBmSignup2',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProBmSignup2' }"
          v-if="proData.specify_student == 1 && proData.real_name == 1"
          >报名</router-link
        >
        <!--调查-不认证、认证-匿名↓-->
        <router-link
          :to="{
            name: 'ProBmSignup',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProBmSignup' }"
          v-else
          >报名</router-link
        >
        <router-link
          :to="{
            name: 'ProBmData',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProBmData' }"
          >数据</router-link
        >
        <router-link
          :to="{
            name: 'ProBmScore',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProBmScore' }"
          >成绩</router-link
        >
        <router-link
          :to="{
            name: 'ProBmStatistics',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProBmStatistics' }"
          >统计</router-link
        >

        <router-link
          :to="{
            name: 'ProBmNotice',
            query: { id: id, type: type, ftype: ftype },
          }"
          :class="{ on: activedRouteName == 'ProBmNotice' }"
          >通知</router-link
        >
      </div>
      <div class="btn">
        <span @click="popFn('isEdit')" title="基本设置"
          ><em class="iconfont iconshezhi"></em><span>编辑</span></span
        >
        <span @click="popFn('isShare')" title="链接设置"
          ><em class="iconfont iconfenxiang"></em><span>链接</span></span
        >

        <span @click="popFn('isStyle')" title="外观设置"
          ><em class="iconfont iconwaiguan"></em><span>外观</span></span
        >
        <a
          :href="
            proData.specify_student == 2 && !proData.exam_text
              ? `https://asktrue.co/#/exam/main/${proData.exam_id}/${proData.projectType}/${proData.vip_id}`
              : (proData.projectType == 2 || proData.projectType == 3) &&
                proData.real_name == 2
              ? `https://asktrue.co/#/exam/loginpg/${proData.exam_id}/${proData.projectType}/${proData.vip_id}/${proData.create_id}`
              : `https://asktrue.co/#/exam/login/${proData.exam_id}/${proData.projectType}/${proData.vip_id}/${proData.create_id}`
          "
          target="_blank"
        >
          <span>
            <em class="iconfont iconpreview1" title="预览"></em>
            <span>预览</span>
          </span>
        </a>
      </div>
      <router-link
        title="关闭"
        :to="{ name: 'Activity', params: { tab: 'Activity', back: '1' } }"
        class="close el-icon-close"
      ></router-link>
    </header>
    <router-view></router-view>
    <el-drawer
      :visible.sync="other.show"
      :size="other.isStyle ? '100%' : '70%'"
      :show-close="other.isStyle ? false : true"
      :before-close="handleClose"
      :key="other.key"
      v-if="other.show"
      custom-class="asktrue-drawer"
    >
      <Form
        :isOnOff="isOnOff"
        :proData="proData"
        @refresh="
          handleClose();
          getProjectEditFn();
        "
        v-if="other.isEdit"
      />
      <Share
        source="xm"
        :proData="proData"
        :examData="{
          examId: id,
          isJk: proData.face_recognition,
          type: type,
          vipId: proData.vip_id,
          goodStatus: proData.goods_status,
        }"
        @closePop="handleClose()"
        v-if="other.isShare"
      />
      <Mark
        ref="mark"
        :data="proData"
        :id="id"
        :type="type"
        @closePop="handleClose()"
        v-if="other.isMark"
      />
      <Style
        :id="proData.exam_id"
        :type="proData.projectType"
        :ftype="proData.from_type"
        @closePop="handleClose()"
        v-if="other.isStyle"
      />
    </el-drawer>
    <Mustfill
      v-if="other.isMustFillSet"
      :isMustFillSet="other.isMustFillSet"
      :freeFormP="freeForm"
      :examId="Number(proData.exam_id)"
      :projectType="Number(proData.projectType)"
      @closePop="handleClose()"
    />
  </div>
</template>

<script>
import Bus from "@/views/staff/js/bus"; //总线事件
import Form from "@/views/staff/components/asktrue/EditProject"; //引入考试编辑表单
import Share from "@/views/staff/components/asktrue/PopShare"; //引入分享弹窗
// import Mark from "./mark"; //引入阅卷弹窗
import Style from "./styleView"; //引入分享弹窗
// import { getData } from "@/js/data.js";
// import { selectParams } from "@/api/asktrue/project/commodity";
// import Mustfill from "../components/Mustfill"; //引入自定义表单（必填项）
import { getProjectEdit, delProjectReq } from "@/api/asktrue/project/project";
// import { newGetQuesInfoReq } from "@/api/asktrue/paper/paper";

export default {
  components: {
    Form,
    Share,
    //  Mark,
    Style,
    //  Mustfill
  },
  data() {
    return {
      id: 0,
      type: 0, //项目类型:1考试 2调查 3评估 4报名 5投票
      ftype: 0, //项目来源，2是自建，1是购买（不显示问卷）
      proData: {}, //详情数据
      freeForm: [], //已设置自定义表单项
      other: {
        show: false, //弹窗开关
        isEdit: false, //编辑开关
        isShare: false, //分享开关
        isMark: false, //阅卷设置开关
        isStyle: false,
        isMustFillSet: false, //必填项设置弹窗展开状态
        key: "",
      },
      isOnOff: {
        isTimeTerm: true, //有效期开关
        isAB: true, //AB卷开关
        isCheat: false, //防作弊开关
        isMonitor: false, //监控开关
        isMustFill: true, //必填项开关
      },
    };
  },
  activated() {},
  //页面初始加载
  created() {
    Bus.$on(
      "getData",
      function () {
        this.getProjectEditFn();
      }.bind(this)
    );
    this.id = this.$route.query.id;
    this.type = this.$route.query.type;
    this.ftype = this.$route.query.ftype;
    this.proData = {};
    this.getProjectEditFn();
  },

  deactivated() {
    //离开页面取消监听
    Bus.$off("getData");
  },
  computed: {
    userId() {
      return this.$store.state.asktrue.userInfo.userId;
    },
    activedRouteName() {
      return this.$route.name;
    },
  },
  methods: {
    //获取详情数据
    getProjectEditFn() {
      let params = {
        examId: this.id,
        projectType: this.type,
      };
      getProjectEdit(params).then((res) => {
        if (res.code == "200") {
          this.proData = res.data.result;
          this.$store.dispatch("asktrue/setProInfo", this.proData);
          this.$emit("getList");
        }
      });
    },
    //上线下线
    examStatusChange() {
      let list = [
        {
          examId: this.proData.exam_id,
          projectType: this.proData.projectType,
        },
      ];
      let params = {
        opeType: this.proData.exam_status == 1 ? 2 : 1, //1-上线,2-下线
        list: list,
      };
      delProjectReq(params).then((res) => {
        if (res.code == "200") {
          let msg = params.opeType == 1 ? "上线" : "下线";
          this.$message.success(msg + "成功");
          this.getProjectEditFn();
        }
      });
    },
    // 显示弹窗
    popFn(isTrue) {
      console.log(isTrue);
      this.other.show = true;
      this.other[isTrue] = true;
      this.other.key = new Date().getTime();
      if (this.other.isMark) {
        this.$nextTick(() => {
          this.$refs.mark.getUrlFn();
        });
      }
    },
    // 关闭弹窗
    handleClose() {
      this.other.show = false;
      this.other.isEdit = false;
      this.other.isShare = false;
      this.other.isMark = false;
      this.other.isStyle = false;
      this.other.isMustFillSet = false;
    },
    // //必填项接口
    // getMustfillFn() {
    //   this.other.isMustFillSet = true;
    //   // 调用新的接口
    //   let data = {
    //     examId: this.proData.exam_id,
    //     paperId: -1,
    //     paperTypeId: 0,
    //     projectType: this.proData.projectType,
    //   };
    //   console.log(data);
    //   newGetQuesInfoReq(data).then((res) => {
    //     if (res.code == "200") {
    //       this.freeForm = [];

    //       res.data.result.map((item) => {
    //         let value = "";
    //         let level = 1;
    //         if (
    //           item.infoTypeId != 1 &&
    //           item.infoTypeId != 4 &&
    //           (item.infoTypeId != 5) & (item.infoTypeId != 116)
    //         ) {
    //           //下拉
    //           value = [{ answer: "" }, { answer: "" }];
    //           level = item.xlLevelSet
    //             ? Number(item.xlLevelSet.split("#zbw#")[0])
    //             : 1;
    //         }
    //         let formObj = {
    //           examId: item.examId,
    //           infoId: item.infoId,
    //           title: item.infoText1,
    //           placeholder:
    //             level > 1 && item.xlLevelSet
    //               ? item.xlLevelSet.split("#zbw#")[1]
    //               : item.infoText2,
    //           placeholder2:
    //             level > 1 && item.xlLevelSet
    //               ? item.xlLevelSet.split("#zbw#")[2]
    //               : "",
    //           type: item.displayStyle == 1 ? "textarea" : "select",
    //           infoTypeId: item.infoTypeId,
    //           option: [],
    //           status: item.status,
    //           isPrev: true,
    //           xlLevelNeed: item.xlLevelNeed,
    //           isSys: item.startSystemAddr == 1 ? true : false,
    //           value,
    //           level,
    //         };
    //         //改变选项数组
    //         if (item.displayStyle == 2) {
    //           //下拉
    //           if (item.startSystemAddr == 1) {
    //             //启用系统数据
    //             let ts = [
    //               { type: "tSchool", infoTypeId: 8 },
    //               { type: "tMajor", infoTypeId: 7 },
    //               { type: "tTrade", infoTypeId: 14 },
    //               { type: "tCity", infoTypeId: 9 },
    //               { type: "tJobKind", infoTypeId: 12 },
    //             ];
    //             let parameterType = ts.find(
    //               (ele) => ele.infoTypeId == item.infoTypeId
    //             ).type;
    //             this.getOption(parameterType).then((data) => {
    //               formObj.option = data;
    //               //改变自定义表单数组
    //               this.freeForm.push(formObj);
    //             });
    //           } else {
    //             //文本
    //             item.list.map((oitem) => {
    //               formObj.option.push({
    //                 infoId: oitem.infoId,
    //                 label: oitem.optionText,
    //                 status: item.status == 2 ? 2 : oitem.status || 1,
    //                 id: oitem.selectId,
    //               });
    //             });
    //             this.freeForm.push(formObj);
    //           }
    //         } else {
    //           //文本
    //           item.list.map((oitem) => {
    //             formObj.option.push({
    //               infoId: oitem.infoId,
    //               label: oitem.optionText,
    //               status: item.status == 2 ? 2 : oitem.status || 1,
    //               id: oitem.selectId,
    //             });
    //           });
    //           this.freeForm.push(formObj);
    //         }
    //       });
    //     }
    //   });
    // },
    // //获取参数选项-学历、院校等
    // async getOption(item) {
    //   let tempOption = [];
    //   if (!item) {
    //     return;
    //   }
    //   await selectParams({ parameterType: item }).then((res) => {
    //     if (res.code == "200") {
    //       let selectArr = res.data.result || [];
    //       selectArr.forEach((ele) => {
    //         let valId = item == "tSchool" ? ele.city_id || "0000" : ele.value;
    //         let obj = {
    //           label: ele.label,
    //           id: valId,
    //           state: false,
    //           isShow: true,
    //           status: 1,
    //         };
    //         let selId = String(obj.id);
    //         if (selId.length < 2) {
    //           return false;
    //         }
    //         obj.id =
    //           String(valId).length / 2 == 2
    //             ? selId.substring(0, 2) + "-" + selId.substring(2, 4)
    //             : String(valId).length / 2 == 3
    //             ? selId.substring(0, 2) +
    //               "-" +
    //               selId.substring(2, 4) +
    //               "-" +
    //               selId.substring(4, 6)
    //             : selId;
    //         tempOption.push(obj);
    //       });
    //       if (item == "tSchool" || item == "tCity") {
    //         //院校/地点
    //         tempOption = tempOption.concat(
    //           JSON.parse(
    //             JSON.stringify(getData("provinceVar")).replace(
    //               /value/g,
    //               "label"
    //             )
    //           )
    //         );
    //         tempOption.forEach((ele) => {
    //           ele.state = false;
    //           ele.status = 1;
    //         });
    //       }
    //     }
    //   });
    //   return tempOption;
    // },
  },
};
</script>

<style lang="less" scoped>
@import "@/views/staff/assets/css/public.css";
.main-warpper {
  display: flex;
  flex-direction: column;
  padding: 70px 0 0 0;
  position: relative;
  height: 100vh;
  overflow: hidden;
  header {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: fixed;
    left: 0px;
    top: 0px;
    width: 100%;
    height: 70px;
    background-color: #fff;
    border-bottom: 1px solid #f2f2f2;

    z-index: 10;
    padding: 0 20px 0 20px;
    .left {
      display: flex;
      align-items: center;
      min-width: 300px;

      width: 30%;
      /*返回*/
      .back {
        margin-right: 20px;
        em {
          font-size: 20px;
          color: var(--second-theme-color);
          font-weight: 700;
        }
        span {
          font-size: 12px;
          vertical-align: middle;
        }
      }
      h1 {
        max-width: 300px;
        font-size: 22px;
        font-weight: 400;
        position: relative;
        padding-right: 30px;
        em {
          position: absolute;
          top: 50%;
          right: 0px;
          transform: translateY(-50%);
          font-size: 20px;
          cursor: pointer;
          &.iconshangxian {
            color: var(--second-theme-color);
          }
        }
      }
      .state {
        i {
          margin: 0 15px;
          color: #ccc;
        }
        span {
          font-size: 14px;
          color: var(--second-theme-color);
        }
      }
    }
    .menuUl {
      flex: 1;
      display: flex;
      justify-content: center;
      align-items: center;
      gap: 15px;
      height: 100%;
      width: 0;
      padding: 0;
      background-color: #fff;
      a {
        color: #333;
        padding: 0 10px;
        font-size: 15px;
        position: relative;
        &.on {
          font-weight: 700;
          color: var(--second-theme-color);
          border-color: var(--second-theme-color);
          &::after {
            content: "";
            position: absolute;
            bottom: -26px;
            left: 50%;
            transform: translateX(-50%);
            width: 30%;
            height: 2px;
            background: var(--second-theme-color);
          }
        }
      }
    }
    .btn {
      display: flex;
      align-items: center;
      gap: 20px;
      width: 30%;
      justify-content: flex-end;
      span {
        display: flex;
        align-items: center;
        gap: 3px;
        font-size: 12px;
        color: #999;
        span {
          display: none;
        }
        em {
          font-size: 14px;
          color: inherit;
          cursor: pointer;
        }
      }
    }
    .close {
      font-size: 24px;
      color: #999;
      margin-left: 20px;
    }
  }
}
</style>
