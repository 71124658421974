export default function (axios) {
  return {
    // 审批回款信息
    pmApproveBak(params) {
      return axios.post("/payBack/approveBak", params);
    },
    //回款新增,修改信息
    pmEditPayBack(params, nose) {
      nose = nose ? nose : ""
      return axios.post("/payBack/editPayBack" + nose, params);
    },
    // 回款详细数据
    pmGetPayBackById(params) {
      return axios.get("/payBack/getPayBackById", { params });
    },
    // 废除回款信息，多个回款id逗号分割
    pmRepealBack(params) {
      return axios.post("/payBack/repealBack?backIds=" + params.backIds);
    },
    //回款列表展示
    pmSelectPayBackList(params) {
      return axios.post("/payBack/selectPayBackList", params);
    },
    // 获取可以驳回的阶段
    pmSelectReApproveInfoList(params) {
      return axios.post(
        `/payBack/selectReApproveInfoList/${params.stageId}/${params.processId}/${params.backId}`
      );
    },
    // 获取某个流程阶段的审批人
    pmSelectApproveStageInfo(params) {
      return axios.post(
        `/payBack/selectApproveStageInfo/${params.stageId}/${params.processId}`
      );
    },
  };
}
